import { useEffect, useState } from "react";

import * as Yup from "yup";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmailIcon from "@mui/icons-material/Email";
import PsychologyIcon from "@mui/icons-material/Psychology";
import QuizIcon from "@mui/icons-material/Quiz";
import SavingsIcon from "@mui/icons-material/Savings";

import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { usePostLoginMutation } from "../../services/auth";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { useFormik } from "formik";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";

const Login = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [loginMutation, { isLoading }] = usePostLoginMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Customers",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      label: "Manage Subscription Plans",
      icon: <SubscriptionsIcon />,
      key: "3",
      selected: "/manage-subscription",
    },
    {
      label: "Manage Categories",
      icon: <ListAltIcon />,
      key: "4",
      selected: "/manage-categories",
    },
    {
      label: "Set Points Criteria",
      icon: <ManageAccountsIcon />,
      key: "5",
      selected: "/set-points-criteria",
    },
    {
      label: "Manage Savings Limit",
      icon: <SavingsIcon />,
      key: "6",
      selected: "/manage-savings-limit",
    },

    {
      label: "Manage Round Up",
      icon: <PsychologyIcon />,
      key: "7",
      selected: "/manage-round-up",
    },

    {
      label: "Manage Marketplace",
      icon: <ViewCarouselIcon />,
      key: "8",
      selected: "/manage-banner",
    },
    {
      label: "Manage Email Templates",
      icon: <EmailIcon />,
      key: "9",
      selected: "/manage-emails",
    },
    {
      label: "Manage Notifications",
      icon: <NotificationsActiveIcon />,
      key: "10",
      selected: "/manage-notifications",
    },
    {
      label: "Reports and Analytics",
      icon: <BarChartIcon />,
      key: "11",
      selected: "/analytics",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "12",
      selected: "/cms",
    },
    {
      label: "Manage FAQs",
      icon: <QuizIcon />,
      key: "13",
      selected: "/faq",
    },
    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "14",
      selected: "/manage-subAdmin",
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      key: "15",
      selected: "/settings",
    },
  ];

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let body = {
        email: formik.values.email,
        password: formik.values.password,
        appkey: Date.now(),
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          console.log(response, "kjkjkjkj");
          showToast(response?.message || "Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }

          if (response?.data?.role === "SUB") {
            const permissions = JSON.parse(`${response?.data?.permissions}`);
            if (response?.data?.role === "SUB" && permissions?.length > 0) {
              const firstPermissionLabel = permissions[0]?.label;
              const redirectPath = items.find(
                (item) => item.label === firstPermissionLabel
              )?.selected;

              if (redirectPath) {
                navigate(redirectPath);
              } else {
                console.error("Invalid permission label for redirection");
                // Handle the case where the label doesn't match any item in the array
              }
            }
          } else {
            // Default redirect for other roles or when no permissions are available
            navigate("/dashboard");
          }
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#5acaa7" }}>
            <Typography variant="h5">Log in to Vala Admin</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                type="email"
                id="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
